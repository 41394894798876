import { PoolConfig, QuoteToken } from './types'
// 0x4AECdf4967c6407CB85D3f91a906c1025a96f85A
const pools: PoolConfig[] = [
 /* {
    sousId: 0,
    tokenName: 'EDXA+',
    stakingTokenName: QuoteToken.EDXA,
    stakingTokenAddress: '0x0C9775e4c7439C7CE3b87C8E81Fc5adFaa7D564f',
    contractAddress: {
      97: '0x4122BBBfDBD91E021b6B4272fc7Ef365a4ddd808',
      56: '',
    },
    refEnable:true,
    projectLink: 'https://trustwallet.comx/',
    sortOrder: 0,
    tokenDecimals: 18,
  },
  {
    sousId: 1,
    tokenName: 'EDXA++',
    stakingTokenName: QuoteToken.EDXA,
    stakingTokenAddress: '0x0C9775e4c7439C7CE3b87C8E81Fc5adFaa7D564f',
    contractAddress: {
      97: '0xc04062B397dD52575C6C4cecba950FE15407dBE6',
      56: '',
    },
    refEnable:true,
    projectLink: 'https://trustwallet.comx/',
    sortOrder: 1,
    tokenDecimals: 18,
  },
  {
    sousId: 2,
    tokenName: 'EDXA+++',
    stakingTokenName: QuoteToken.EDXA,
    stakingTokenAddress: '0x0C9775e4c7439C7CE3b87C8E81Fc5adFaa7D564f',
    contractAddress: {
      97: '0x74519Eb218e8696005Ad6f2107Cf496B1F40c04C',
      56: '',
    },
    refEnable:true,
    projectLink: 'https://trustwallet.comx/',
    sortOrder: 2,
    tokenDecimals: 18,
  },
  {
    sousId: 3,
    tokenName: 'EDXA++++',
    stakingTokenName: QuoteToken.EDXA,
    stakingTokenAddress: '0x0C9775e4c7439C7CE3b87C8E81Fc5adFaa7D564f',
    contractAddress: {
      97: '0x06Fc16AeF3acf9B9F9Ef537B0aaE986c68179b59',
      56: '',
    },
    refEnable:false,
    projectLink: 'https://trustwallet.comx/',
    sortOrder: 3,
    tokenDecimals: 18,
  } */
]

export default pools
