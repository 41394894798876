import { MenuEntry } from '@edxa/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  /* {
    label: 'Trade v1',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange v1',
        href: 'https://exchange.edxa.co/#/swap',
      },
      {
        label: 'Liquidity v1',
        href: 'https://exchange.edxa.co/#/pool',
      },
    ],
  }, */
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://exchange-v2.edxa.co/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://exchange-v2.edxa.co/#/pool',
      },
    ],
  },
  {
    label: 'Incubator',
    icon: 'IncubatorIcon',
    href: '/farms',
  },


  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'ICO',
    icon: 'IfoIcon',
    href: '/ifo',
  },
  {
    label: 'Referral',
    icon: 'ReferralIcon',
    href: '/referral',
  },

/*  {
    label: 'Faucet',
    icon: 'FaucetIcon',
    href: '/faucet',
  }, */

  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Audit',
        href: 'https://github.com/interfinetwork/smart-contract-audits/blob/main/MasterChef_EDXA_AuditReport_InterFi.pdf',
      },
      {
        label: 'Coincodex',
        href: 'https://coincodex.com/crypto/edxa-labs-token/',
      },
      {
        label: 'DappRadar',
        href: 'https://dappradar.com/binance-smart-chain/exchanges/edxa-labs',
      },
      {
        label: 'Pancakeswap',
        href: 'https://pancakeswap.info/token/0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
      },/*
      {
        label: 'Poocoin Charts',
        href: 'https://poocoin.app/tokens/0x952cebf65f3a9f11ef0222aefe0608d26621b097',
      }, */
    ],
  },
  /*
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/edxa/',
      },
      {
        label: 'Docs',
        href: 'https://edxa.gitbook.io/',
      },
      {
        label: 'Blog',
        href: 'https://edxa.medium.com/',
      },
    ],
  }, */
]

export default config
