import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import cakeABI from 'config/abi/cake.json'
import { QuoteToken } from 'config/constants/types'
import multicall from 'utils/multicall'
import BigNumber from 'bignumber.js'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId >= 0)
  const callsStartBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[CHAIN_ID],
      name: 'startBlock',
    }
  })
  const callsEndBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[CHAIN_ID],
      name: 'bonusEndBlock',
    }
  })

  const starts = await multicall(sousChefABI, callsStartBlock)
  const ends = await multicall(sousChefABI, callsEndBlock)

  return [
    ...poolsWithEnd.map((p, index) => ({
      sousId: p.sousId,
      startBlock: new BigNumber(starts[index]).toJSON(),
      endBlock: new BigNumber(ends[index]).toJSON(),

    }))
  ]


}

export const fetchPoolsTotalStatking = async () => {
  const nonBnbPools = poolsConfig.filter((p) => p.stakingTokenName !== QuoteToken.WBNB)

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: poolConfig.stakingTokenAddress,
      name: 'balanceOf',
      params: [poolConfig.contractAddress[CHAIN_ID]],
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(cakeABI, callsNonBnbPools)

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
    }))
  ]
}

export const fetchPoolsRewardPerBlock = async () => {
  const poolsWithReward = poolsConfig.filter((p) => p.sousId >= 0)
  const callRewardPerBlock = poolsWithReward.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[CHAIN_ID],
      name: 'rewardPerBlock',
    }
  })

  // console.log(callRewardPerBlock)
  const reward = await multicall(sousChefABI, callRewardPerBlock)

  return [
    ...poolsWithReward.map((p, index) => ({
      sousId: p.sousId,
      rewardPerBlock: new BigNumber(reward[index]).toJSON(),
    }))
  ]


}
