import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    risk: 5,
    isTokenOnly: false,
    isFarm: true,
    lpSymbol: 'EDXA',
    lpAddresses: {
      56: '0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
      97: '0x0C9775e4c7439C7CE3b87C8E81Fc5adFaa7D564f',
    },
    tokenSymbol: 'EDXA',
    tokenAddresses: {
      56: '0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
      97: '0x0C9775e4c7439C7CE3b87C8E81Fc5adFaa7D564f',
    },

    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    risk: 5,
    isTokenOnly: false,
    isFarm: false,
    lpSymbol: 'EDXA-BUSD LP v1',
    lpAddresses: {
      56: '0xa01584d303a339fc62586e1091a44e227b4bf3d6',
      97: '0xfc47a64e8d1871f0a59b9bab546351878689dc6b',
    },
    tokenSymbol: 'EDXA',
    tokenAddresses: {
      56: '0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
      97: '0x0C9775e4c7439C7CE3b87C8E81Fc5adFaa7D564f',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    risk: 5,
    isTokenOnly: false,
    isFarm: false,
    lpSymbol: 'EDXA-BNB LP v1',
    lpAddresses: {
      56: '0xbcde486eff2f191928b8bd1e57839b75f404f855',
      97: '0x08241510c0A4A88c2b6138ad88a9B816078f61B3',
    },
    tokenSymbol: 'EDXA',
    tokenAddresses: {
      56: '0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
      97: '0x0C9775e4c7439C7CE3b87C8E81Fc5adFaa7D564f',
    },
    quoteTokenSymbol: QuoteToken.WBNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    risk: 3,
    isTokenOnly: false,
    isFarm: false,
    lpSymbol: 'BNB-BUSD LP v1',
    lpAddresses: {
      56: '0xe84584dbBdA2261c14914B57b728F6b0A3A44d76',
      97: '0x276674595E4D54a605bA6C5300026990D6b01c69',
    },
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '0x05214674fCB7e95e48Fbc273d981F4e9b4a6C88E',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    risk: 5,
    isTokenOnly: false,
    isFarm: false,
    lpSymbol: 'EDXA-SHIB LP v1',
    lpAddresses: {
      56: '0x35f1a31D96929311286248CECabE1b575c8ec3cA',
      97: '',
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      56: '0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.EDXA,
    quoteTokenAdresses: contracts.cake,
  },
  {
    risk: 5,
    isTokenOnly: false,
    isFarm: false,
    lpSymbol: 'EDXA-BUSD LP v2',
    lpAddresses: {
      56: '0x9c56Cd3F60DC3981289241ac880AA253DcBFd574',
      97: '',
    },
    tokenSymbol: 'EDXA',
    tokenAddresses: {
      56: '0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },{
    risk: 5,
    isTokenOnly: false,
    isFarm: false,
    lpSymbol: 'EDXA-BNB LP v2',
    lpAddresses: {
      56: '0xc2b44b7a9209b60846414264edc411663e75e59a',
      97: '',
    },
    tokenSymbol: 'EDXA',
    tokenAddresses: {
      56: '0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.WBNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    risk: 5,
    isTokenOnly: false,
    isFarm: false,
    lpSymbol: 'EDXA-SHIB LP v2',
    lpAddresses: {
      56: '0x039354fd09d1829c72B9C6AD646506f9C48d3754',
      97: '',
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      56: '0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.EDXA,
    quoteTokenAdresses: contracts.cake,
  },{
    risk: 5,
    isTokenOnly: false,
    isFarm: false,
    lpSymbol: 'EDXA-USDT LP v2',
    lpAddresses: {
      56: '0xd2494edaeea842845c5928650e0010e7999c3c29',
      97: '',
    },
    tokenSymbol: 'EDXA',
    tokenAddresses: {
      56: '0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    risk: 3,
    isTokenOnly: false,
    isFarm: false,
    lpSymbol: 'BNB-BUSD LP v2',
    lpAddresses: {
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
      97: '',
    },
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

].map((farm, index) => ({ ...farm, pid: index }))

export default farms
