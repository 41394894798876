import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getMasterChefAddress } from 'utils/addressHelpers'
import farmsConfig from 'config/constants/farms'
import { QuoteToken } from '../../config/constants/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchFarms = async () => {
  const data = await Promise.all(
    farmsConfig.map(async (farmConfig) => {
      try {
        const lpAdress = farmConfig.lpAddresses[CHAIN_ID]
        const calls = [
          // Balance of token in the LP contract
          {
            address: farmConfig.tokenAddresses[CHAIN_ID],
            name: 'balanceOf',
            params: farmConfig.isFarm ? ['0x9c56cd3f60dc3981289241ac880aa253dcbfd574'] : [lpAdress],
          },
          // Balance of quote token on LP contract
          {
            address: farmConfig.isFarm ? '0xe9e7cea3dedca5984780bafc599bd69add087d56' : farmConfig.quoteTokenAdresses[CHAIN_ID],
            /* address: farmConfig.quoteTokenAdresses[CHAIN_ID], */
            name: 'balanceOf',
            /* params: [lpAdress], */
            params: farmConfig.isFarm ? ['0x9c56cd3f60dc3981289241ac880aa253dcbfd574'] : [lpAdress],

          },
          // Balance of LP tokens in the master chef contract
          {
            address: farmConfig.isFarm ? farmConfig.tokenAddresses[CHAIN_ID] : lpAdress,
            name: 'balanceOf',
            params: [getMasterChefAddress()],
          },
          // Total supply of LP tokens
          {
            address: lpAdress,
            name: 'totalSupply',
          },
          // Token decimals
          {
            address: farmConfig.tokenAddresses[CHAIN_ID],
            name: 'decimals',
          },
          // Quote token decimals
          {
            address: farmConfig.quoteTokenAdresses[CHAIN_ID],
            name: 'decimals',
          },
        ]

        const [tokenBalanceLP, quoteTokenBlanceLP, lpTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
          await multicall(erc20, calls)

        let tokenAmount
        let lpTotalInQuoteToken
        let tokenPriceVsQuote
        if (farmConfig.isTokenOnly) {
          tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals))
          if (farmConfig.tokenSymbol === QuoteToken.BUSD && farmConfig.quoteTokenSymbol === QuoteToken.BUSD) {
            tokenPriceVsQuote = new BigNumber(1)
          } else {
            tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
          }
          lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
        } /* else if((farmConfig.lpSymbol === 'EDXA') && (farmConfig.tokenSymbol==='EDXA')){

         lpTotalInQuoteToken = tokenAmount;
         tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP);

        } */
        else if (farmConfig.isFarm) {
          tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals))
          if (farmConfig.tokenSymbol === QuoteToken.BUSD && farmConfig.quoteTokenSymbol === QuoteToken.BUSD) {
            tokenPriceVsQuote = new BigNumber(1)
          } else {
            tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
          }
          lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)

          // console.log(quoteTokenBlanceLP)
        }
        else {
          // Ratio in % a LP tokens that are in staking, vs the total number in circulation
          const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

          // Total value in staking in quote token value
          lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
            .div(new BigNumber(10).pow(18))
            .times(new BigNumber(2))
            .times(lpTokenRatio)

          // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
          tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
          const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
            .div(new BigNumber(10).pow(quoteTokenDecimals))
            .times(lpTokenRatio)

          if (tokenAmount.comparedTo(0) > 0) {
            tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
          } else {
            tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
          }
        }

        const [info, totalAllocPoint, tacoPerBlock] = await multicall(masterchefABI, [
          {
            address: getMasterChefAddress(),
            name: 'poolInfo',
            params: [farmConfig.pid],
          },
          {
            address: getMasterChefAddress(),
            name: 'totalAllocPoint',
          },
          {
            address: getMasterChefAddress(),
            name: 'tokenPerBlock',
          },
        ])

        const allocPoint = new BigNumber(info.allocPoint._hex)
        // const farmTax = new BigNumber(info.harvestTax._hex)
        const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

        return {
          ...farmConfig,
          tokenAmount: tokenAmount.toJSON(),
          // quoteTokenAmount: quoteTokenAmount,
          lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
          tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
          poolWeight: poolWeight.toNumber(),
          multiplier: `${allocPoint.div(100).toString()}X`,
          depositFeeBP: info.depositFeeBP,
          // harvestTax: `${farmTax.div(100).toString()}%`,
          tacoPerBlock: new BigNumber(tacoPerBlock).toNumber(),
          totalSupplyLP : lpTotalSupply.toString(),
          totalSupplyLPinMC : lpTokenBalanceMC.toString(),
          quoteTokenBlanceLP: quoteTokenBlanceLP.toString()
        }
      } catch (ex) {
        // console.log('ERROR')
        // console.log(farmConfig.lpSymbol)
        // console.log(ex)
        return Promise.reject()
      }
    }),
  )

  return data
}

export default fetchFarms
