export default {
  cake: {
    56: '0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
    97: '0x0C9775e4c7439C7CE3b87C8E81Fc5adFaa7D564f',
  },
  masterChef: {
    56: '0x0463587C9D3C39130C59f6Cf96739a15b27D2a3d',
    97: '0xa39170D33E246eDAB7844065eE15F569F7611C06',
  },
  /*
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  }, */
  mulltiCall: {
    56: '0xbdCd8aD86C713EB3CfA9dD3c92780a3dE0da4D60',
    97: '0x919B2bDc5f93cc5b47F379813873443242F2ED61',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x42d31a3A83171FF829f9561aA2785ACB32dae76e',
  },
  usdt: {
    56: '0x55d398326f99059ff775485246999027b3197955',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0x05214674fCB7e95e48Fbc273d981F4e9b4a6C88E',
  },
  referral: {
    56: '0x08F177b6d1d46e7cFC19336D21b4A4862D7db27d',
    97: '0x35e07dfdc280EE07C0Ec450A28E70341F14Ac15A',
  },
  faucet: {
    56: '0x258A4D1DAe661df27c86718a849d8072147aFA05',
    97: '0x8210b7c2a1AA4D2D0649B19d384E5Db43f721727',
  },
}
